import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useRouteError } from 'react-router-dom';

/**
 * Error Boundary Component
 * Displays user-friendly error messages and provides recovery options
 * 
 * @component
 * @extends {Component}
 * @returns {React.ReactElement} Error UI component or children
 */
class ErrorBoundaryClass extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null,
      errorCount: 0
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error("Error caught in ErrorBoundary:", error, errorInfo);
    }

    // Update state with error info and increment error count
    this.setState(prevState => ({
      errorInfo,
      errorCount: prevState.errorCount + 1
    }));

    // Call onError prop if provided
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  resetError = () => {
    this.setState({ 
      hasError: false, 
      error: null, 
      errorInfo: null 
    });
  }

  renderErrorMessage() {
    const { error } = this.state;
    const { fallback, routeError } = this.props;

    // If custom fallback is provided, use it
    if (fallback) {
      return fallback({ error: error || routeError, resetError: this.resetError });
    }

    const displayError = error || routeError;

    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4" role="alert">
        <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Oops! Something went wrong
          </h1>
          <p className="text-gray-600 mb-6">
            {displayError?.message || 'An unexpected error occurred. Please try again later.'}
          </p>
          <div className="space-y-4">
            <button
              onClick={() => window.location.reload()}
              className="w-full bg-sky-600 text-white py-2 px-4 rounded hover:bg-sky-700 transition-colors focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              aria-label="Refresh page"
            >
              Refresh Page
            </button>
            <button
              onClick={() => {
                window.location.href = '/';
                this.resetError();
              }}
              className="w-full bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
              aria-label="Go to home page"
            >
              Go to Home
            </button>
            {process.env.NODE_ENV === 'development' && (
              <details className="mt-4 text-left">
                <summary className="cursor-pointer text-gray-600 hover:text-gray-800">
                  Error Details
                </summary>
                <pre className="mt-2 p-4 bg-gray-100 rounded text-sm overflow-auto">
                  {displayError?.stack || JSON.stringify(displayError, null, 2)}
                </pre>
              </details>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.hasError || this.props.routeError) {
      return this.renderErrorMessage();
    }

    return this.props.children;
  }
}

ErrorBoundaryClass.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.func,
  onError: PropTypes.func,
  routeError: PropTypes.any
};

// Wrapper component to handle both class errors and route errors
const ErrorBoundary = (props) => {
  const routeError = useRouteError();
  return <ErrorBoundaryClass {...props} routeError={routeError} />;
};

export default ErrorBoundary;