import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const BookingCalendar = ({ selectedDate, selectedTime, onDateChange, onTimeSelect }) => {
  const timeSlots = [
    '09:00', '10:00', '11:00', '13:00', 
    '14:00', '15:00', '16:00', '17:00'
  ];

  // Disable past dates and weekends
  const tileDisabled = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today || date.getDay() === 0 || date.getDay() === 6;
  };

  return (
    <div className="space-y-6">
      <div>
        <h4 className="text-lg font-medium text-gray-900 mb-4">Select Date</h4>
        <Calendar
          onChange={onDateChange}
          value={selectedDate}
          tileDisabled={tileDisabled}
          minDate={new Date()}
          className="w-full rounded-lg border border-gray-200"
        />
      </div>

      {selectedDate && (
        <div>
          <h4 className="text-lg font-medium text-gray-900 mb-4">Select Time</h4>
          <div className="grid grid-cols-4 gap-2">
            {timeSlots.map((time) => (
              <button
                key={time}
                onClick={() => onTimeSelect(time)}
                className={`p-2 text-center rounded-lg transition-colors ${
                  selectedTime === time
                    ? 'bg-sky-100 text-sky-600'
                    : 'bg-gray-50 hover:bg-sky-50'
                }`}
              >
                {time}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCalendar;
