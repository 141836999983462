/**
 * Main Application Component
 * Root component that sets up routing, authentication, and global context.
 * 
 * @module App
 * @requires react-router-dom - For application routing
 * @requires @react-oauth/google - Google OAuth integration
 * @requires react - Core React functionality
 * 
 * Related Files:
 * - src/AppContext.js - Global application state management
 * - src/pages/* - All page components
 * - src/components/Layout.js - Main layout wrapper
 * - src/components/ErrorBoundary.js - Error handling component
 * 
 * Environment Variables:
 * - REACT_APP_GOOGLE_CLIENT_ID: Google OAuth client ID (required)
 */

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Suspense } from 'react';
import { AppProvider } from './AppContext';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import ConsultantProfile from './pages/ConsultantProfile';
import MyAppointments from './pages/MyAppointments';
import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

/**
 * Application Router Configuration
 * Defines the routing structure and component hierarchy
 * 
 * Route Structure:
 * - / (Layout)
 *   - / (HomePage)
 *   - /about (AboutUs)
 *   - /consultant/:consultantId (ConsultantProfile)
 *   - /my-appointments (MyAppointments)
 * 
 * Features:
 * - Error boundary for route-level error handling
 * - Nested routing with shared layout
 * - Index routes for main pages
 */
const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: 'about',
        element: <AboutUs />
      },
      {
        path: 'consultant/:consultantId',
        element: <ConsultantProfile />
      },
      {
        path: 'my-appointments',
        element: <MyAppointments />
      }
    ]
  }
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    },
  },
})

/**
 * Root Application Component
 * Sets up the application with required providers and routing
 * 
 * Features:
 * - Google OAuth integration
 * - Global state management
 * - Suspense for code splitting
 * - Environment variable validation
 * 
 * @component
 * @returns {React.ReactElement|null} The rendered application or null if configuration is invalid
 * 
 * Related Components:
 * - AppProvider - Global state management
 * - Layout - Main application layout
 * - ErrorBoundary - Error handling
 */
function App() {
  // Validate required environment variables
  if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    console.error('Missing REACT_APP_GOOGLE_CLIENT_ID environment variable');
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Toaster position="top-right" />
        <Suspense fallback={<div>Loading...</div>}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <AppProvider>
              <RouterProvider router={router} />
            </AppProvider>
          </GoogleOAuthProvider>
        </Suspense>
      </>
    </QueryClientProvider>
  );
}

export default App;
