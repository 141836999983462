import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { CalendarDaysIcon, ClockIcon, UserIcon } from '@heroicons/react/24/outline';
import LoadingSpinner from '../components/LoadingSpinner';
const MyAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if not logged in
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchAppointments = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await api.getMyAppointments();
        console.log('Raw appointments response:', response); // Debug log
        
        // Handle different response formats
        const appointmentsData = Array.isArray(response) ? response : 
                               response.bookings ? response.bookings :
                               response.data ? response.data : [];
                               
        console.log('Processed appointments data:', appointmentsData); // Debug log
        setAppointments(appointmentsData);
      } catch (err) {
        console.error('Error fetching appointments:', err);
        // Check for specific error types
        if (err.status === 401) {
          navigate('/login');
          setError('Please log in to view your appointments');
        } else if (err.status === 404) {
          setError('No appointments found');
        } else {
          setError(err.message || 'Failed to load appointments. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppointments();
  }, [user, navigate]);

  if (!user) {
    return null; // Prevent flash of content before redirect
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Error Loading Appointments</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="h-64 bg-gradient-to-r from-sky-900 to-sky-700">
        <div className="absolute inset-0 bg-black/20" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      </div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-32 relative">
        <h1 className="text-3xl font-bold text-white mb-8">My Appointments</h1>
        
        {appointments.length === 0 ? (
          <div className="bg-white rounded-xl shadow-lg p-6 text-center">
            <h2 className="text-xl font-semibold text-gray-700 mb-2">No Appointments Found</h2>
            <p className="text-gray-500">You haven't booked any appointments yet.</p>
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {appointments.map((appointment) => (
              <div key={appointment._id} className="bg-white rounded-xl shadow-lg p-6">
                <div className="flex items-center mb-4">
                  <img
                    src={appointment.consultant?.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(appointment.consultant?.name || 'Consultant')}&size=128&background=0369a1&color=ffffff`}
                    alt={appointment.consultant?.name}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-900">{appointment.consultant?.name}</h3>
                    <p className="text-sm text-gray-500">{appointment.service?.title}</p>
                  </div>
                </div>
                
                <div className="space-y-3">
                  <div className="flex items-center text-gray-700">
                    <CalendarDaysIcon className="h-5 w-5 mr-2 text-sky-600" />
                    <span>{new Date(appointment.date).toLocaleDateString()}</span>
                  </div>
                  <div className="flex items-center text-gray-700">
                    <ClockIcon className="h-5 w-5 mr-2 text-sky-600" />
                    <span>{new Date(appointment.date).toLocaleTimeString()}</span>
                  </div>
                  <div className="flex items-center text-gray-700">
                    <UserIcon className="h-5 w-5 mr-2 text-sky-600" />
                    <span>Status: {appointment.status}</span>
                  </div>
                </div>
                
                {appointment.status === 'pending' && (
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      onClick={() => {/* TODO: Handle cancellation */}}
                      className="px-4 py-2 text-sm font-medium text-red-600 hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {/* TODO: Handle rescheduling */}}
                      className="px-4 py-2 text-sm font-medium text-sky-600 hover:text-sky-700"
                    >
                      Reschedule
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAppointments;
