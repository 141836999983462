import React from 'react';

const NoteSection = ({ note, onNoteChange }) => {
  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Notes</h3>
      <textarea
        value={note}
        onChange={(e) => onNoteChange(e.target.value)}
        rows={4}
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm resize-none"
        placeholder="Add any additional information or special requests..."
      />
    </div>
  );
};

export default NoteSection;
