import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { AppContext } from '../AppContext';
import LogoBrand from './header/LogoBrand';
import UserProfile from './header/UserProfile';
import { BellIcon } from '@heroicons/react/24/solid';

const Header = () => {
  const { user, login, logout } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const headerStyle = useMemo(() => {
    return scrolled ? 'bg-white shadow' : 'bg-transparent';
  }, [scrolled]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const credential = credentialResponse.credential;
      await login(credential);
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${headerStyle}`}>
      <div className="container mx-auto px-4">
        <div className="h-16 flex items-center justify-between">
          <LogoBrand />

          <div className="flex items-center gap-4">
            {user ? (
              <>
                <button
                  className={`relative p-2 ${
                    scrolled ? 'text-gray-600 hover:text-gray-900' : 'text-white hover:text-sky-100'
                  }`}
                >
                  <BellIcon className="h-6 w-6" />
                  <span className="absolute top-2 right-2 h-2 w-2 bg-red-500 rounded-full" />
                </button>
                <UserProfile
                  user={user}
                  isOpen={dropdownOpen}
                  onToggle={() => setDropdownOpen(!dropdownOpen)}
                  onLogout={logout}
                  scrolled={scrolled}
                />
              </>
            ) : (
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => console.log('Login Failed')}
                useOneTap={false}
                theme={scrolled ? 'outline' : 'filled_blue'}
                size="large"
                text="continue_with"
                shape="pill"
                width="200px"
              />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
