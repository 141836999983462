const LoadingSpinner = () => {
    const spinnerStyles = {
      width: '5rem', // Spinner circle size
      height: '5rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#02324C transparent', // Top color and transparent sides
      borderRadius: '50%', // Perfect circle
      animation: 'spin 1s linear infinite', // Rotate animation
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    const containerStyles = {
      minHeight: '100vh', // Full screen height
      display: 'flex', // Flexbox for centering
      alignItems: 'center', // Vertical centering
      justifyContent: 'center', // Horizontal centering
      position: 'relative', // Required for independent positioning of spinner and logo
    };
  
    const logoWrapperStyles = {
      position: 'absolute', // Static logo stays independent
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // Center alignment
      filter: 'invert(14%) sepia(88%) saturate(307%) hue-rotate(158deg) brightness(91%) contrast(88%)', // Color adjustment filter
    };
  
    const logoStyles = {
      height: '4rem', // Adjust logo size for better visibility
      width: 'auto', // Maintain aspect ratio
    };
  
    return (
      <div style={containerStyles}>
        {/* Static logo */}
        <div style={logoWrapperStyles}>
          <img
            src="/logo.svg"
            alt="Lavocato"
            style={logoStyles}
            className="transition-colors duration-200"
          />
        </div>
        {/* Rotating spinner */}
        <div style={spinnerStyles}></div>
        <style>
          {`
            @keyframes spin {
              to {
                transform: rotate(360deg);
              }
            }
          `}
        </style>
      </div>
    );
  };
  
  export default LoadingSpinner;
  