import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../../styles/logo.css';

const LogoBrand = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Link to="/" className="flex items-center space-x-2">
      <img
        src="/logo.svg"
        alt="Lavocato"
        className={`h-16 w-auto transition-colors duration-200 ${
          isScrolled ? 'filter-sky-950' : 'filter-white'
        }`}
      />
      <span className={`text-xl font-bold transition-colors duration-200 ${
        isScrolled ? 'text-sky-950' : 'text-white'
      }`}>
        Lavocato
      </span>
    </Link>
  );
};

export default LogoBrand;
