import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import {
  UserIcon,
  CalendarIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/solid'; // Use solid for filled icons

const UserProfile = ({ user, isOpen, onToggle, onLogout, scrolled }) => {
  return (
    <div className="relative">
      <button
        onClick={onToggle}
        className={`flex items-center gap-2 ${
          scrolled ? 'text-gray-700 hover:text-sky-600' : 'text-white hover:text-sky-100'
        }`}
      >
        <UserIcon className="h-6 w-6" />
        {user && <span className="text-sm font-medium">{user.name.split(' ')[0]}</span>}
      </button>

      {isOpen && user && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            <Link
              to="/profile"
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <UserIcon className="mr-2 inline-block h-5 w-5" />
              Profile
            </Link>

            <Link
              to="/my-appointments"
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <CalendarIcon className="mr-2 inline-block h-5 w-5" />
              My Appointments
            </Link>

            <Link
              to="/settings"
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <Cog6ToothIcon className="mr-2 inline-block h-5 w-5" />
              Settings
            </Link>

            <button
              onClick={onLogout}
              className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
            >
              <ArrowRightOnRectangleIcon className="mr-2 inline-block h-5 w-5" />
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
