import React from 'react';
import { DocumentIcon, XMarkIcon } from '@heroicons/react/24/outline';

const DocumentUpload = ({ documents, onDocumentChange, onAddDocument }) => {
  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Upload Documents</h3>
      <div className="space-y-4">
        {documents.map((doc, index) => (
          <div key={index} className="flex items-center space-x-4">
            <div className="flex-1">
              <label className="block w-full px-4 py-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50">
                <div className="flex items-center">
                  <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600">
                    {doc ? doc : 'Choose a file...'}
                  </span>
                </div>
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => onDocumentChange(index, e)}
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                />
              </label>
            </div>
            {index === documents.length - 1 && (
              <button
                type="button"
                onClick={onAddDocument}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700"
              >
                Add More
              </button>
            )}
          </div>
        ))}
      </div>
      <p className="mt-2 text-sm text-gray-500">
        Supported formats: PDF, DOC, DOCX, XLS, XLSX, JPG, PNG (max 10MB)
      </p>
    </div>
  );
};

export default DocumentUpload;
