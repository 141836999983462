import React, { useState, useContext, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { GoogleLogin } from '@react-oauth/google';
import { AppContext } from '../../../AppContext';
import ConsultantInfo from './ConsultantInfo';
import BookingCalendar from './BookingCalendar';
import DocumentUpload from './DocumentUpload';
import NoteSection from './NoteSection';
import api from '../../../utils/api';
import { toast } from 'react-hot-toast';

const ConsultantModal = ({ isOpen, onClose, consultant, defaultService }) => {
  const { user, login, isAuthenticating } = useContext(AppContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedService, setSelectedService] = useState(defaultService || null);
  const [documents, setDocuments] = useState(['']);
  const [note, setNote] = useState('');
  const [isDocumentUploadOpen, setIsDocumentUploadOpen] = useState(false);
  const [isNoteSectionOpen, setIsNoteSectionOpen] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      // Reset state when modal opens
      setSelectedDate(new Date());
      setSelectedTime(null);
      setDocuments(['']);
      setNote('');
      setError(null);
      
      // Set selected service
      if (defaultService) {
        setSelectedService(defaultService);
      } else if (consultant?.services?.length > 0) {
        setSelectedService(consultant.services[0]);
      }
    }
  }, [isOpen, consultant, defaultService]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // Reset time when date changes
  };

  const handleDocumentChange = async (index, event) => {
    const file = event.target.files[0];
    if (!file) return;

    const newDocuments = [...documents];
    newDocuments[index] = file.name;
    setDocuments(newDocuments);

    // Store the actual file for upload
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles[index] = file;
    setUploadedFiles(newUploadedFiles);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, '']);
  };

  const handleBookAppointment = async () => {
    if (!selectedDate || !selectedTime || !selectedService) {
      setError('Please select a date, time, and service');
      return;
    }

    if (!user) {
      setError('Please log in to book an appointment');
      return;
    }

    setIsBooking(true);
    setError(null);

    try {
      // Create FormData
      const formData = new FormData();
      
      // Create booking data object for validation
      const bookingData = {
          consultantId: consultant._id,
          serviceId: selectedService._id,
          date: selectedDate.toISOString().split('T')[0],
          time: selectedTime,
          duration: selectedService.sessionDuration || '60',
          notes: note || '',
          status: 'pending',
          paymentStatus: 'pending'
      };

      // Validate data before sending
      const requiredFields = ['consultantId', 'serviceId', 'date', 'time', 'duration'];
      const missingFields = requiredFields.filter(field => !bookingData[field]);
      
      if (missingFields.length > 0) {
          throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }

      // Append validated data to FormData
      Object.entries(bookingData).forEach(([key, value]) => {
          formData.append(key, value);
          console.log(`Appending ${key}:`, value);
      });

      // Append documents
      if (uploadedFiles.length > 0) {
          uploadedFiles.forEach((file) => {
              if (file) {
                  formData.append('documents', file);
                  console.log('Appending document:', file.name);
              }
          });
      }

      // Make the API call
      const response = await fetch(`${process.env.REACT_APP_API_URL}/bookings/book`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formData
      });

      if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message || 'Failed to create booking');
          throw new Error(errorData.message || 'Failed to create booking');
      }

      const result = await response.json();
      console.log('Booking created:', result);
      toast.success('Booking created successfully');
      onClose();
    } catch (err) {
      console.error('Booking error:', err);
      setError(err.message || 'Failed to create booking');
    } finally {
      setIsBooking(false);
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const credential = credentialResponse.credential;
      await login(credential);
    } catch (error) {
      console.error('Error during login:', error);
      setError('Failed to login. Please try again.');
    }
  };

  const handleServiceSelect = (service) => {
    if (service) {
      const normalizedService = {
        ...service,
        price: service.pricePerSession || service.price || 0
      };
      setSelectedService(normalizedService);
    } else {
      setSelectedService(null);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        
        <div className="relative bg-white rounded-lg max-w-4xl w-full mx-auto">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="flex flex-col md:flex-row">
            {consultant && (
              <ConsultantInfo
                consultant={consultant}
                selectedService={selectedService}
                onServiceChange={handleServiceSelect}
              />
            )}

            <div className="md:w-1/2 p-6">
              {error && (
                <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
                  {error}
                </div>
              )}

              {!user ? (
                <div className="mt-6 text-center">
                  <p className="mb-4 text-gray-600">Please sign in to book an appointment</p>
                  <GoogleLogin
                    onSuccess={handleGoogleLogin}
                    onError={() => {
                      console.error('Google login failed');
                      setError('Google sign-in failed. Please try again.');
                    }}
                    useOneTap={false}
                    theme="filled_blue"
                    size="large"
                    width="300"
                  />
                </div>
              ) : (
                <>
                  <BookingCalendar
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    onDateChange={handleDateChange}
                    onTimeSelect={setSelectedTime}
                  />
                       <DocumentUpload
                        documents={documents}
                        onDocumentChange={handleDocumentChange}
                        onAddDocument={handleAddDocument}
                      />
                      
                      <NoteSection
                        note={note}
                        onNoteChange={setNote}
                      />

                  <div className="mt-6">
                    <button
                      onClick={handleBookAppointment}
                      disabled={isBooking}
                      className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:opacity-50"
                    >
                      {isBooking ? 'Booking...' : 'Book Appointment'}
                    </button>
                  </div>

                  {user && (
                    <>
                 
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConsultantModal;
