const API_BASE_URL = process.env.REACT_APP_API_URL;

const handleResponse = async (response) => {
  // First check if there's a response body
  const contentType = response.headers.get("content-type");
  const hasJsonResponse = contentType && contentType.includes("application/json");
  
  let responseData = null;
  if (hasJsonResponse) {
    try {
      responseData = await response.json();
    } catch (e) {
      console.error('Failed to parse JSON response:', e);
      throw new Error('Failed to parse server response');
    }
  }

  if (!response.ok) {
    const error = new Error(
      responseData?.message || 
      responseData?.error || 
      `Request failed with status ${response.status}`
    );
    error.status = response.status;
    error.data = responseData;
    throw error;
  }

  return responseData;
};

const getAuthHeader = () => {
  try {
    const token = localStorage.getItem('token');
    if (!token || typeof token !== 'string' || token === 'undefined') {
      return '';
    }
    // Ensure token is properly formatted
    const cleanToken = token.trim();
    if (!cleanToken) return '';
    
    // Check if token already has Bearer prefix
    if (cleanToken.startsWith('Bearer ')) {
      return cleanToken;
    }
    return `Bearer ${cleanToken}`;
  } catch (error) {
    console.error('Error getting auth header:', error);
    return '';
  }
};

export const makeRequest = async (endpoint, options = {}) => {
  const defaultHeaders = {
    'Accept': 'application/json',
    ...(options.body && { 'Content-Type': 'application/json' })
  };

  const authHeader = getAuthHeader();
  console.log('Auth header:', authHeader); // Debug log

  if (authHeader && authHeader !== 'Bearer ') {
    defaultHeaders['Authorization'] = authHeader;
  }

  // Handle query parameters
  let url = `${API_BASE_URL}${endpoint}`;
  if (options.params) {
    const queryString = new URLSearchParams(options.params).toString();
    url = `${url}?${queryString}`;
  }

  console.log('Making request to:', url); // Debug log
  console.log('With headers:', defaultHeaders); // Debug log

  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers
      }
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('API Error:', {
        status: response.status,
        statusText: response.statusText,
        errorData
      });
      throw new Error(errorData.message || 'Server error');
    }

    return await handleResponse(response);
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const api = {
  // Auth endpoints
  login: async (credentials) => {
    try {
      if (!credentials?.credential || typeof credentials.credential !== 'string') {
        throw new Error('Invalid credentials format');
      }

      const response = await makeRequest('/auth/google/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          credential: credentials.credential.trim()
        })
      });

      if (!response?.token) {
        throw new Error('Invalid server response: missing token');
      }

      // Ensure token is clean before storing
      const token = response.token.trim();
      if (!token) {
        throw new Error('Invalid token received from server');
      }

      // Store token without Bearer prefix if it's included
      const cleanToken = token.startsWith('Bearer ') ? token.substring(7).trim() : token;
      localStorage.setItem('token', cleanToken);

      return response;
    } catch (error) {
      console.error('Login failed:', error);
      localStorage.removeItem('token');
      throw new Error(
        error.data?.message || 
        error.message || 
        'Authentication failed'
      );
    }
  },
  
  getCurrentUser: async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      return await makeRequest('/auth/me');
    } catch (error) {
      console.error('Get current user failed:', error);
      throw error;
    }
  },

  // Consultant endpoints
  getConsultants: () => makeRequest('/consultants'),
  getConsultant: async (consultantId) => {
    console.log('API call: getConsultant', consultantId);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/consultants/${consultantId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Unauthorized access');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API response:', data);
      return data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  },

  // Service endpoints
  getServices: () => makeRequest('/services'),
  getService: (id) => makeRequest(`/services/${id}`),

  // Booking endpoints
  createBooking: (bookingData) => makeRequest('/bookings/book', {
    method: 'POST',
    body: JSON.stringify(bookingData)
  }),

  getMyAppointments: () => makeRequest('/bookings/client', {
    method: 'GET'
  }),

  // Document upload
  uploadDocuments: (bookingId, formData) => makeRequest(`/bookings/${bookingId}/documents`, {
    method: 'POST',
    body: formData,
    headers: {} // Let browser set content-type for FormData
  })
};

export default api;
