import React from "react";
import {
  MapPinIcon,
  PhoneIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

const ConsultantInfo = ({ consultant, selectedService, onServiceChange }) => {
  if (!consultant) return null;

  const defaultImage = `https://ui-avatars.com/api/?name=${encodeURIComponent(
    consultant.name || "Consultant"
  )}&size=128`;

  return (
    <div className="p-6 md:w-1/2 border-r border-gray-200">
      <div className="flex items-center space-x-4 mb-6">
        <img
          src={consultant.avatar || defaultImage}
          alt={consultant.name || "Consultant"}
          className="w-20 h-20 rounded-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
        />
        <div>
          <h2 className="text-xl font-bold text-gray-900">
            {consultant.name || "Consultant"}
          </h2>
          <p className="text-gray-600">
            {consultant.specialization || "Professional Consultant"}
          </p>
        </div>
      </div>

      <div className="mb-6">
        <label
          htmlFor="service"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Select Service
        </label>
        <select
          id="service"
          value={selectedService?._id || ''}
          onChange={(e) => {
            const service = consultant.services?.find(s => s._id === e.target.value);
            onServiceChange(service);
          }}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm rounded-md"
        >
          <option value="">Select a service</option>
          {consultant.services?.map((service) => (
            <option key={service._id} value={service._id}>
              {service.title} - ${service.pricePerSession || service.price || 'N/A'}
            </option>
          ))}
        </select>
      </div>

      <div className="space-y-4">
        <div className="flex items-center text-gray-600">
          <MapPinIcon className="h-5 w-5 mr-2" />
          <span>{consultant.location || "Remote"}</span>
        </div>
        {consultant.phoneNumber && (
          <div className="flex items-center text-gray-600">
            <PhoneIcon className="h-5 w-5 mr-2" />
            <span>{consultant.phoneNumber}</span>
          </div>
        )}
        {consultant.email && (
          <div className="flex items-center text-gray-600">
            <EnvelopeIcon className="h-5 w-5 mr-2" />
            <span>{consultant.email}</span>
          </div>
        )}
      </div>

      <div className="mt-6">
        <h3 className="font-semibold mb-2">Specialties</h3>
        <div className="flex flex-wrap gap-2">
          {(
            consultant.specialties ||
            consultant.expertise || ["General Consulting"]
          ).map((specialty, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-600"
            >
              {specialty}
            </span>
          ))}
        </div>
      </div>

      {selectedService && (
        <div className="mt-6">
          <h3 className="font-semibold mb-2">Selected Service Details</h3>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="font-medium text-gray-900">{selectedService.title}</p>
            <p className="text-gray-600 mt-1">{selectedService.description}</p>
            <p className="text-gray-900 font-medium mt-2">
              Duration: {selectedService.sessionDuration || 60} minutes
            </p>
            <p className="text-gray-900 font-medium">
              Price: ${(selectedService.pricePerSession || selectedService.price || 0).toFixed(2)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultantInfo;
